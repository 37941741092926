import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ItemIdGuard = ({ children }) => {
  const searchParams = new URLSearchParams(document.location.search);
  const itemId = searchParams.get('id');

  if (!itemId) {
    return <Navigate to="/" />;
  }

  return <> {children} </>;
};

ItemIdGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ItemIdGuard;
