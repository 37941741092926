import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import NumericTextField from '../NumericTextField';

const Form = ({ onSubmit, onClose }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        phone_number: '',
      }}
      validationSchema={Yup.object().shape({
        phone_number: Yup.string()
          .matches(
            /^\d{7}$/,
            intl.formatMessage({
              id: 'form.formik.validation_schema.phone_number',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'form.formik.validation_schema.required',
            }),
          ),
      })}
      onSubmit={async (values) => {
        const modifiedValues = { ...values };

        modifiedValues.phone_number = String(values.phone_number);

        await onSubmit(modifiedValues);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', py: 1, gap: 1 }}>
            <Box>
              <NumericTextField
                id="form_formik_phone_number"
                name="phone_number"
                label={intl.formatMessage({
                  id: 'form.formik.text_field_label_phone_number',
                })}
                value={values.phone_number}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
                handleBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'flex-end' }}
          >
            <Button color="primary" onClick={onClose} variant="outlined">
              {intl.formatMessage({
                id: 'form_formik.cancel_button_text',
              })}
            </Button>

            <Box>
              {isSubmitting ? (
                <LoadingButton
                  sx={{ width: '100%' }}
                  loading
                  variant="contained"
                >
                  {intl.formatMessage({
                    id: 'form_formik.submit_button_text',
                  })}
                </LoadingButton>
              ) : (
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {intl.formatMessage({
                    id: 'form_formik.submit_button_text',
                  })}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Form;
