/**
 * Converts a date from "YYYY-MM-DD" format to "DD of MM of YYYY" format.
 * @param {string} dateString The date string in "YYYY-MM-DD" format.
 * @param {string} lang The language code for month names ('en' for English, 'pt' for Portuguese).
 * @returns {string} The formatted date string in "DD of MM of YYYY" format.
 */
const getFullDate = (dateString, lang) => {
  const [year, month, day] = dateString.split('-');
  const monthNames = {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    pt: [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ],
  };

  const preposition = lang === 'en' ? 'of' : 'de';

  const monthName = monthNames[lang][parseInt(month, 10) - 1];
  return `${parseInt(day, 10)} ${preposition} ${monthName} ${preposition} ${year}`;
};

module.exports = { getFullDate };
