import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CustomText = ({
  text,
  type,
  fontSize,
  color,
  fontWeight,
  fontFamily,
  textAlign,
  children,
}) => {
  let style = {};

  switch (type) {
    case 'title':
      style = {
        fontSize: 18,
        fontWeight: '500',
        fontFamily: 'Helvetica Neue',
        color: '#535353',
      };
      break;
    case 'text':
      style = {
        fontSize: 14,
        fontWeight: '400',
        fontFamily: 'Helvetica Neue',
        color: '#888888',
      };
      break;
    case 'label':
      style = {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Helvetica Neue',
        color: '#535353',
      };
      break;
    case 'custom':
      style = {
        fontSize,
        fontWeight,
        fontFamily,
        color,
      };
      break;
    default:
      break;
  }

  return (
    <Typography
      style={{ ...style, whiteSpace: 'pre-line' }}
      textAlign={textAlign}
    >
      {text} {children}
    </Typography>
  );
};

CustomText.defaultProps = {
  fontSize: 14,
  fontWeight: '500',
  fontFamily: 'Helvetica Neue',
  color: '#535353',
  textAlign: 'left',
  children: null,
};

CustomText.propTypes = {
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  type: PropTypes.oneOf(['title', 'text', 'label', 'custom']).isRequired,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
};

export default CustomText;
