import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import './style.css';

const commandControlKeys = ['Meta', 'Control'];
const clipboardKeys = ['c', 'C', 'v', 'V', 'X', 'x', 'a', 'A'];

const NumericTextField = ({
  id,
  label,
  error,
  helperText,
  onChange,
  handleBlur,
  value,
  name,
}) => {
  const handlePaste = (e) => {
    // [ˆe]|^[0-9]*$ - regex to match only numbers
    if (!e.target.value.match(/[ˆe]|^[0-9]*$/)) {
      e.preventDefault();
    }
  };

  const [isControlOrCommandKeyPressed, setIsControlOrCommandKeyPressed] =
    useState(false);

  return (
    <TextField
      id={id}
      fullWidth
      error={error}
      label={label}
      helperText={helperText}
      onChange={onChange}
      onBlur={handleBlur}
      value={value}
      name={name}
      type="number"
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        type: 'number',
      }}
      onKeyUp={(e) => {
        if (commandControlKeys.includes(e.key)) {
          setIsControlOrCommandKeyPressed(false);
        }
      }}
      onKeyDown={(e) => {
        const keyPressed = e.key;
        if (
          !(
            (Number(keyPressed) >= 0 && Number(keyPressed) <= 9) ||
            keyPressed === 'Backspace' ||
            keyPressed === 'ArrowLeft' ||
            keyPressed === 'ArrowRight'
          )
        ) {
          if (
            !(
              isControlOrCommandKeyPressed && clipboardKeys.includes(keyPressed)
            )
          ) {
            if (commandControlKeys.includes(keyPressed)) {
              setIsControlOrCommandKeyPressed(true);
            } else {
              e.preventDefault();
            }
          }
        }
      }}
      onPasteCapture={handlePaste}
      onPaste={handlePaste}
    />
  );
};

NumericTextField.defaultProps = {
  id: null,
  error: null,
  helperText: null,
};

NumericTextField.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default NumericTextField;
