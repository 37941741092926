import { Container, Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomSnackBar from '../../components/CustomSnackBar';
import CustomText from '../../components/CustomText';
import FormDialog from '../../components/FormDialog';
import IconLabelButton from '../../components/IconLabelButton';
import PageHeaderTitle from '../../components/PageHeaderTitle';
import Wrapper from '../../components/Wrapper';
import axios from '../../lib/axios';
import theme from '../../theme';
import { getCurrentDate } from '../../utils/getCurrentDate';
import { getDayOfWeek } from '../../utils/getDayOfWeek';
import { getFullDate } from '../../utils/getFullDate';
import getLastLocation from '../../utils/getLastLocation';
import { getTimeNow } from '../../utils/getTimeNow';
import { getLanguageFromLocalStorage } from '../../utils/languagesFunctions';

const Schedule = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [timesList, setTimesList] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const lang = getLanguageFromLocalStorage();

  const searchParams = new URLSearchParams(document.location.search);
  const itemId = searchParams.get('id');

  const checkLastAvailableTime = (times) => {
    const lastAvailableTime = times[times.length - 1];
    return lastAvailableTime.end;
  };

  const fetchItem = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/items/${itemId}`);
      if (response.data) {
        setData(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate('/');
      }
    } catch (error) {
      // errors will be resolved in the future
      console.error('Ocorreu um erro ao carregar os dados da API:', error);
    }
  };

  useEffect(() => {
    if (!data) {
      fetchItem();
    }
  }, [data]);

  const mappedData =
    data && data.route && data.route.available_pick_up_times
      ? Object.entries(data.route.available_pick_up_times)
          .map(([date, times]) => {
            const filteredTimes = times.filter((time) => time.slot > 0);
            if (filteredTimes.length > 0) {
              return {
                date,
                times: filteredTimes.map((time) => ({
                  slots: time.slot,
                  start: time.start,
                  end: time.end,
                })),
              };
            }
            return null; // Filter out this date if all slots are <= 0
          })
          .filter(Boolean) // Filter out null values (dates where all slots are <= 0)
      : [];

  const renderLocation = () => {
    return (
      <Box
        key={Math.random()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <CustomText
          text={intl.formatMessage({ id: 'schedule.location' })}
          type="custom"
          color={theme.palette.grey[700]}
          fontWeight="400"
        />
        <CustomText
          text={getLastLocation(data).name.toUpperCase()}
          type="text"
        />
      </Box>
    );
  };

  const renderDateSelected = () => {
    return (
      <Box
        key={Math.random()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <CustomText
          text={intl.formatMessage({ id: 'schedule_time.date' })}
          type="custom"
          color={theme.palette.grey[700]}
          fontWeight="400"
        />
        <CustomText
          text={`${getDayOfWeek(dateSelected, lang)}, ${getFullDate(dateSelected, lang)}`.toUpperCase()}
          type="text"
        />
      </Box>
    );
  };

  const renderDatesForPickup = () => {
    if (mappedData.length === 0) {
      return (
        <Box>
          <CustomText
            text={intl.formatMessage({ id: 'schedule_time.not_available' })}
            type="text"
          />
        </Box>
      );
    }

    return mappedData.map((value) => {
      const isDateAvailable = value.date >= getCurrentDate();
      const isToday = getCurrentDate() === value.date;
      const isTimeAvailable =
        getTimeNow() <= checkLastAvailableTime(value.times);

      if (isDateAvailable && (!isToday || isTimeAvailable)) {
        return (
          <Box
            key={Math.random()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: 1,
              px: 2,
              borderRadius: 2,
              cursor: 'pointer',
              boxShadow:
                '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)',
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={() => {
              setDateSelected(value.date);
              setTimesList(value.times);
            }}
          >
            <CustomText
              type="custom"
              color={theme.palette.white.main}
              text={getDayOfWeek(value.date, lang)}
            />
            <CustomText
              type="custom"
              color={theme.palette.white.main}
              text={getFullDate(value.date, lang).toUpperCase()}
            />
          </Box>
        );
      }
      return null;
    });
  };

  const isAvailable = (value) => {
    return value > 0;
  };

  const handleSubmit = async () => {
    setOpenConfirmationDialog(false);

    const body = {
      pickup_time: {
        date: dateSelected,
        start: timeSelected.start,
        end: timeSelected.end,
      },
    };

    try {
      setIsLoading(true);
      await axios.patch(`/items/${itemId}`, body);
      navigate({
        pathname: '/item-details',
        search: `id=${itemId}`,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setTimesList(null);
      setIsSnackOpen(true);
      await fetchItem();
      console.error(error);
    }
  };

  const confirmSubmit = (value) => {
    setOpenConfirmationDialog(true);
    setTimeSelected(value);
  };

  const renderTimesForPickup = () => {
    return timesList.map((value) => {
      if (!isAvailable(value.slots)) return null;

      const isDifferentDate = dateSelected !== getCurrentDate();
      const isTimeValid = getTimeNow() <= value.end;

      if (isDifferentDate || isTimeValid) {
        return (
          <Box
            key={Math.random()}
            sx={{
              display: 'flex',
              py: 1,
              px: 2,
              borderRadius: 2,
              cursor: 'pointer',
              gap: 0.5,
              color: theme.palette.white.main,
              backgroundColor: theme.palette.primary.main,
              boxShadow:
                '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)',
            }}
            onClick={() => confirmSubmit(value)}
          >
            <CustomText
              type="custom"
              text={value.start}
              color={theme.palette.white.main}
            />
            -
            <CustomText
              type="custom"
              text={value.end}
              color={theme.palette.white.main}
            />
          </Box>
        );
      }
      return null;
    });
  };

  const resetData = () => {
    setDateSelected(null);
    setTimeSelected(null);
    setTimesList(null);
  };

  const closeConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const renderContent = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '@media (max-width: 500px)': {
              gap: 1,
            },
          }}
        >
          {renderLocation()}
          {dateSelected && renderDateSelected()}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {timesList ? renderTimesForPickup() : renderDatesForPickup()}
        </Box>
        {dateSelected && (
          <Button onClick={() => resetData()} variant="outlined">
            {intl.formatMessage({ id: 'actions.go_back' })}
          </Button>
        )}
      </Box>
    );
  };

  const handleOnSnackBarClosed = () => {
    setIsSnackOpen(false);
  };

  const handleOnConfirmationDialogClosed = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'schedule.helmet.title' })}</title>
      </Helmet>
      <Wrapper>
        <Container
          maxWidth="md"
          sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}
        >
          <CustomSnackBar
            severity="error"
            open={isSnackOpen}
            message={intl.formatMessage({ id: 'schedule.error' })}
            onClose={handleOnSnackBarClosed}
          />
          {dateSelected && timeSelected && (
            <FormDialog
              title={intl.formatMessage({
                id: 'schedule.confirmation.title',
              })}
              isOpen={openConfirmationDialog}
              handleClose={handleOnConfirmationDialogClosed}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <CustomText
                  type="label"
                  color={theme.palette.primary.main}
                  text={intl.formatMessage({
                    id: 'schedule.confirmation.description',
                  })}
                />
                <CustomText
                  type="text"
                  color={theme.palette.primary.main}
                  text={`${getDayOfWeek(dateSelected, lang)}, ${getFullDate(dateSelected, lang)} ${intl.formatMessage({ id: 'format_date.text' })} ${timeSelected.start}`}
                />
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconLabelButton
                    onClick={() => handleSubmit()}
                    variant="contained"
                    title={intl.formatMessage({
                      id: 'actions.yes',
                    })}
                  />
                  <IconLabelButton
                    onClick={() => closeConfirmationDialog()}
                    variant="outlined"
                    title={intl.formatMessage({
                      id: 'actions.no',
                    })}
                  />
                </Box>
              </Box>
            </FormDialog>
          )}
          {!isLoading && isLoading !== null ? (
            <Box>
              <Box sx={{ mt: 2 }}>
                <PageHeaderTitle
                  title={
                    dateSelected ? 'schedule_time.title' : 'schedule.title'
                  }
                  goBackPath="/item-details"
                  itemId={itemId}
                  hasBackButton={!dateSelected}
                />
                <CustomText
                  text={intl.formatMessage({
                    id: dateSelected
                      ? 'schedule_time.subtitle'
                      : 'schedule.subtitle',
                  })}
                  type="text"
                />
              </Box>
              <Box sx={{ mt: 2 }}>{renderContent()}</Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default Schedule;
