import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1a3a5c',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    gray: {
      main: '#F2F2F2',
    },
    white: {
      main: '#FFFFFF',
    },
    success: {
      main: '#73D888',
    },
    info: {
      main: '#AAB9C8',
    },
  },
});

export default theme;
