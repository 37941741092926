import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const IconLabelButton = ({ title, icon, variant, loading, onClick }) =>
  loading ? (
    <LoadingButton sx={{ width: '100%' }} loading variant={variant}>
      {title}
    </LoadingButton>
  ) : (
    <Button
      sx={{ width: '100%' }}
      variant={variant}
      startIcon={icon}
      onClick={onClick}
      loading
    >
      {title}
    </Button>
  );

IconLabelButton.defaultProps = {
  loading: false,
  icon: null,
};

IconLabelButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']).isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default IconLabelButton;
