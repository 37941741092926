import { Download } from '@mui/icons-material';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { Container, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import ContentBox from '../../components/ContentBox';
import CustomSnackBar from '../../components/CustomSnackBar';
import CustomText from '../../components/CustomText';
import FormDialog from '../../components/FormDialog';
import IconLabelButton from '../../components/IconLabelButton';
import PageHeaderTitle from '../../components/PageHeaderTitle';
import SispForm from '../../components/SispForm';
import Wrapper from '../../components/Wrapper';
import axios from '../../lib/axios';
import fetchSettings from '../../utils/fetchSettings';
import getLastLocation from '../../utils/getLastLocation';

const { REACT_APP_API_URL } = process.env;

const PaymentInformation = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);

  const searchParams = new URLSearchParams(document.location.search);
  const itemId = searchParams.get('id');
  const success = searchParams.get('success');
  const errorMessage = searchParams.get('errorMessage');

  const fetchItem = async () => {
    try {
      const response = await axios.get(`/items/${itemId}`);
      if (response.data) {
        setData(response.data);
      } else {
        setIsLoading(false);
        navigate('/');
      }
    } catch (error) {
      // errors will be resolved in the future
      console.error('Ocorreu um erro ao carregar os dados da API:', error);
    }
  };

  const handleFetchs = async () => {
    setIsLoading(true);
    const [, settings] = await Promise.all([fetchItem(), fetchSettings()]);
    setIsPaymentEnabled(settings.allowOnlinePayment);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!data) {
      handleFetchs();
    }
  }, [data]);

  useEffect(() => {
    if (success || errorMessage) {
      setIsSnackOpen(true);
    }
  }, [success, errorMessage]);

  const handlePayButtonClick = () => {
    setIsFormOpen(true);
  };

  const handleDownloadButtonClick = () => {
    window.open(data.payment.receipt_url, '_blank');
  };

  const handleOnClose3dsForm = () => {
    setIsFormOpen(false);
  };

  const handleOnSnackBarClosed = () => {
    setIsSnackOpen(false);
  };

  const renderPayButton = () => {
    if (
      isPaymentEnabled &&
      data &&
      data.payment &&
      data.payment.status === false
    ) {
      return (
        <IconLabelButton
          title={intl.formatMessage({
            id: 'payment_information.container.icon_label_button.pay.title',
          })}
          icon={<MonetizationOnRoundedIcon />}
          variant="contained"
          onClick={handlePayButtonClick}
        />
      );
    }
    return null;
  };

  const renderDownloadButton = () => {
    if (
      data &&
      data.payment &&
      data.payment.status &&
      data.payment.receipt_url
    ) {
      return (
        <IconLabelButton
          title={intl.formatMessage({
            id: 'payment_information.container.icon_label_button.download.title',
          })}
          icon={<Download />}
          variant="contained"
          onClick={handleDownloadButtonClick}
        />
      );
    }
    return null;
  };

  const renderReceipt = () => {
    if (data.payment && data.payment.receipt_url) {
      return <ContentBox item={data} type="qr-code" />;
    }
    return null;
  };

  const renderContent = () => {
    if (getLastLocation(data).current_location && data.payment) {
      if (isPaymentEnabled) {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ContentBox item={data} type="payment-information" />
            <Box>{renderPayButton()}</Box>
            <Box>{renderReceipt()}</Box>
            <Box>{renderDownloadButton()}</Box>
          </Box>
        );
      }

      if (data.payment.status) {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ContentBox item={data} type="payment-information" />
            <Box>{renderReceipt()}</Box>
            <Box>{renderDownloadButton()}</Box>
          </Box>
        );
      }
    }

    if (isPaymentEnabled) {
      return (
        <CustomText
          text={intl.formatMessage({
            id: 'payment_information.no_data',
          })}
          type="text"
        />
      );
    }

    return (
      <CustomText
        text={intl.formatMessage({
          id: 'payment_information.payment_not_allowed',
        })}
        type="text"
      />
    );
  };

  const handleSubmit = async (values) => {
    const response = await axios.post(
      `/items/${data.id}/paymentintents`,
      values,
    );

    if (response.status === 200) {
      window.location.assign(
        `${REACT_APP_API_URL}/paymentintents/${response.data.payment_intent_id}`,
      );
    }
  };

  const renderSispLogos = () => {
    if (isPaymentEnabled) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            mt: 2,
          }}
        >
          <img
            alt="Visa Logo"
            src="/static/sisp/visa-logo.svg"
            width={48}
            style={{ height: 'auto', aspectRatio: '1 / 1' }}
          />
          <img
            alt="Mastercard Logo"
            src="/static/sisp/mastercard-logo.svg"
            width={48}
            style={{ height: 'auto', aspectRatio: '1 / 1' }}
          />
          <img
            alt="Vinti4 Logo"
            src="/static/sisp/vinti4-logo.png"
            width={40}
            height={40}
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'payment_information.helmet.title' })}
        </title>
      </Helmet>
      <Wrapper>
        <Container
          maxWidth="md"
          sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}
        >
          {!isLoading && isLoading !== null ? (
            <>
              <Box sx={{ mt: 2 }}>
                <PageHeaderTitle
                  title="payment_information.title"
                  goBackPath="/item-details"
                  itemId={itemId}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  mt: 2,
                }}
              >
                <Box>{renderContent()}</Box>
                <Box>{renderSispLogos()}</Box>
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormDialog
                  title={intl.formatMessage({
                    id: 'payment_information.container.form_dialog.title',
                  })}
                  description={`${intl.formatMessage({
                    id: 'payment_information.container.form_dialog.description',
                  })} `}
                  descriptionComplement={data.name}
                  isOpen={isFormOpen}
                  handleClose={handleOnClose3dsForm}
                >
                  <SispForm
                    onSubmit={handleSubmit}
                    onClose={handleOnClose3dsForm}
                  />
                </FormDialog>
              </Box>
              <CustomSnackBar
                severity="success"
                open={isSnackOpen && success}
                message="Pagamento realizado com sucesso!"
                onClose={handleOnSnackBarClosed}
              />
              <CustomSnackBar
                severity="error"
                open={isSnackOpen && errorMessage}
                message={errorMessage}
                onClose={handleOnSnackBarClosed}
              />
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default PaymentInformation;
