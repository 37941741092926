const SUPPORTED_LANGUAGES = {
  pt: {
    iso: 'pt',
    value: 'pt',
    label: 'Português',
  },
  en: {
    iso: 'us',
    value: 'en',
    label: 'English',
  },
};

module.exports = {
  SUPPORTED_LANGUAGES,
};
