import { Box, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CustomText from '../CustomText';

const DetailsContainer = ({ label, value, paymentInfo, divider }) => {
  const intl = useIntl();
  const renderChipStatusButton = () => {
    if (paymentInfo && paymentInfo.payment) {
      if (paymentInfo && paymentInfo.payment.status) {
        return (
          <Chip
            label={intl.formatMessage({
              id: 'chip.label.paid',
            })}
            color="success"
            sx={{ color: '#ffffff' }}
          />
        );
      }
      return (
        <Chip
          label={intl.formatMessage({
            id: 'chip.label.unpaid',
          })}
          color="info"
          sx={{ color: '#ffffff' }}
        />
      );
    }
    return <CustomText text={value} type="text" textAlign="right" />;
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          py: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <Box sx={{ minWidth: 110 }}>
          <CustomText text={label} type="label" />
        </Box>
        {renderChipStatusButton()}
      </Box>
      {divider && <Divider />}
    </>
  );
};

DetailsContainer.defaultProps = {
  paymentInfo: {},
  value: null,
  divider: true,
};

DetailsContainer.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  paymentInfo: PropTypes.shape({
    amount: PropTypes.number,
    status: PropTypes.bool,
  }),
  divider: PropTypes.bool,
};

export default DetailsContainer;
