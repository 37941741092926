import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Menu, MenuItem, Container } from '@mui/material';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import theme from '../../theme';
import { SUPPORTED_LANGUAGES } from '../../utils/constants';
import { setLanguageOnLocalStorage } from '../../utils/languagesFunctions';
import CustomText from '../CustomText';

const Header = ({ changeLanguageOnLocale, language }) => {
  const navigate = useNavigate();

  const getFlag = (countryCode) => {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };

  const getButtonLabel = (lang) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      <CustomText type="title" text={getFlag(SUPPORTED_LANGUAGES[lang].iso)} />
      <CustomText
        fontSize={10}
        type="custom"
        text={SUPPORTED_LANGUAGES[lang].label}
      />
    </Box>
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(language);
  const [buttonLabel, setButtonLabel] = useState(getButtonLabel(selectedLang));

  const handleOpenMenu = (event) => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    setButtonLabel(getButtonLabel(selectedLang));
    setLanguageOnLocalStorage(selectedLang);
    changeLanguageOnLocale(selectedLang);
    handleCloseMenu();
  }, [changeLanguageOnLocale, selectedLang]);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.main,
      }}
    >
      <Container>
        <Box
          sx={{
            py: 1,
            display: 'flex',
            bgcolor: theme.palette.primary.main,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            onClick={() => navigate('/')}
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
              py: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <img
              src="/logo_white.png"
              alt="Atlantic Shipping Logo White"
              loading="lazy"
              width={35}
            />
            <Box sx={{ '@media (max-width: 430px)': { display: 'none' } }}>
              <CustomText
                type="custom"
                text="Atlantic Shipping Company"
                fontSize={20}
                color={theme.palette.white.main}
              />
            </Box>
          </Box>
          <Button
            id="language-selection-button"
            color="white"
            variant="contained"
            size="small"
            onClick={handleOpenMenu}
            endIcon={<ArrowDropDownIcon />}
            sx={{
              py: 0,
              height: 40,
              borderRadius: 5,
              textTransform: 'none',
            }}
          >
            {buttonLabel}
          </Button>
          <Menu
            id="languages-menu"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleCloseMenu}
          >
            {Object.keys(SUPPORTED_LANGUAGES).map((lang) => (
              <MenuItem key={lang} onClick={() => setSelectedLang(lang)}>
                {getButtonLabel(lang)}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  changeLanguageOnLocale: propTypes.func.isRequired,
  language: propTypes.string.isRequired,
};

export default Header;
