import axios from '../lib/axios';

const fetchSettings = async () => {
  try {
    const response = await axios.get('/settings');

    return {
      allowOnlinePayment: response.data.allow_online_payment,
    };
  } catch (error) {
    console.error('Erro ao tentar buscar dados de configuração: ', error);
  }

  return {
    allowOnlinePayment: false,
  };
};

export default fetchSettings;
