import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Container, Box, TextField, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomText from '../../components/CustomText';
import IconLabelButton from '../../components/IconLabelButton';
import Wrapper from '../../components/Wrapper';
import axios from '../../lib/axios';

const Search = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [itemId, setItemId] = useState('');
  const [loading, setLoading] = useState(false);
  const [itemNotFound, setItemNotFound] = useState(false);

  const fetchItem = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/items/${itemId}`);
      if (response.data) {
        navigate({
          pathname: '/item-details',
          search: `?id=${itemId}`,
        });
      } else {
        setItemNotFound(true);
      }
      setLoading(false);
    } catch (error) {
      // errors will be resolved in the future
      console.error('Ocorreu um erro ao carregar os dados da API:', error);
      setLoading(false);
    }
  };

  const renderItemNotFound = () => {
    if (itemNotFound) {
      return (
        <CustomText
          text={intl.formatMessage({ id: 'item_not_found.title' })}
          type="text"
        />
      );
    }

    return null;
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'search.helmet.title' })}</title>
      </Helmet>
      <Wrapper>
        <Container maxWidth="md">
          <Box>
            <Box sx={{ my: 2 }}>
              <CustomText
                text={intl.formatMessage({ id: 'search.title' })}
                type="title"
              />
              <CustomText
                text={intl.formatMessage({ id: 'search.description_1' })}
              >
                <span style={{ fontWeight: 'bold' }}> Dock Receipt </span>
                {intl.formatMessage({ id: 'search.description_2' })}
              </CustomText>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TextField
                label={intl.formatMessage({
                  id: 'search.text_field.track_code',
                })}
                sx={{ m: 1, width: '100%' }}
                InputLabelProps={{
                  sx: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixedOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    fetchItem();
                  }
                }}
                value={itemId}
                onChange={(e) => setItemId(e.target.value)}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <IconLabelButton
                title={intl.formatMessage({ id: 'actions.search' })}
                onClick={fetchItem}
                icon={<SearchOutlinedIcon />}
                variant="contained"
                loading={loading}
              />
            </Box>
            <Box>{renderItemNotFound()}</Box>
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

export default Search;
