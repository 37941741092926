import {
  LocationOn as CurrentLocationIcon,
  RadioButtonChecked as LocationIcon,
} from '@mui/icons-material';
import { Box, Stepper, Step, StepLabel, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React from 'react';
import { useIntl } from 'react-intl';

import formattedCurrency from '../../utils/formattedCurrency';
import getLastLocation from '../../utils/getLastLocation';
import CustomText from '../CustomText';
import DetailsContainer from '../DetailsContainer';

const renderIcon = (currentLocationIndex) => {
  const RouteStepIcon = ({ icon, className }) => {
    const RouteIcon = styled('div')(({ theme, locationState }) => ({
      color:
        locationState && locationState.completed
          ? theme.palette.primary.main
          : theme.palette.grey[400],
    }));

    let stepIcon;
    let completed;
    if (icon <= currentLocationIndex + 1) {
      completed = true;
      if (icon === currentLocationIndex + 1) {
        stepIcon = <CurrentLocationIcon />;
      } else {
        stepIcon = <LocationIcon />;
      }
    } else {
      completed = false;
      stepIcon = <LocationIcon />;
    }

    return (
      <RouteIcon
        locationState={{ completed }}
        className={className}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {stepIcon}
      </RouteIcon>
    );
  };
  return RouteStepIcon;
};
const ContentBox = ({ item, type, isPaymentEnabled }) => {
  const intl = useIntl();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const content = () => {
    const lastLocation = getLastLocation(item);
    const currentLocationIndex = item.route.locations.indexOf(
      item.route.locations.find((location) => location.current_location),
    );

    const shouldDisplayPaymentInformation = () =>
      !isPaymentEnabled &&
      !!item.payment &&
      !!item.payment.amount &&
      !item.payment.status;

    switch (type) {
      case 'list':
        return (
          <>
            <DetailsContainer
              label={intl.formatMessage({
                id: 'content_box.details_container.id',
              })}
              value={item.id}
            />
            <DetailsContainer
              label={intl.formatMessage({
                id: 'content_box.details_container.name',
              })}
              value={item.name}
              divider={Boolean(
                item.description || lastLocation.current_location,
              )}
            />
            {item.description && (
              <DetailsContainer
                label={intl.formatMessage({
                  id: 'content_box.details_container.description',
                })}
                value={item.description.replace(/\\n/g, '\n')}
                divider={lastLocation.current_location}
              />
            )}
            {lastLocation.current_location && (
              <DetailsContainer
                label={intl.formatMessage({
                  id: 'content_box.details_container.location',
                })}
                value={lastLocation.name}
                divider={shouldDisplayPaymentInformation()}
              />
            )}
            {shouldDisplayPaymentInformation() && (
              <DetailsContainer
                label={intl.formatMessage({
                  id: 'content_box.details_container.amount',
                })}
                value={formattedCurrency(item.payment.amount)}
                divider={false}
              />
            )}
          </>
        );
      case 'payment-information':
        return (
          <>
            <DetailsContainer
              label={intl.formatMessage({
                id: 'content_box.details_container.amount',
              })}
              value={formattedCurrency(item.payment.amount)}
            />
            {lastLocation.current_location && (
              <DetailsContainer
                label={intl.formatMessage({
                  id: 'content_box.details_container.status',
                })}
                divider={false}
                paymentInfo={item}
              />
            )}
          </>
        );
      case 'item-routes':
        return (
          <Box sx={{ maxHeight: 300, p: 2, overflow: 'auto' }}>
            <Stepper
              orientation={isMobile ? 'vertical' : 'horizontal'}
              activeStep={currentLocationIndex}
              alternativeLabel={!isMobile}
            >
              {item.route.locations.map((location) => (
                <Step key={location.name}>
                  <StepLabel
                    StepIconComponent={renderIcon(currentLocationIndex)}
                  >
                    <CustomText
                      type="text"
                      textAlign="center"
                      text={location.name}
                    />
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        );
      case 'qr-code':
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <QRCode size={250} value={item.payment.receipt_url} color="pink" />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        py: 2,
        borderRadius: 2,
        boxShadow:
          '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)',
      }}
    >
      <Box sx={{ px: 3 }}>{content()}</Box>
    </Box>
  );
};

ContentBox.defaultProps = {
  isPaymentEnabled: false,
};

ContentBox.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isPaymentEnabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default ContentBox;
