import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import NumericTextField from '../NumericTextField';
import TextInput from '../TextInput';

const SispForm = ({ onSubmit, onClose }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        email: '',
        phone_number: '',
        nif: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(
            intl.formatMessage({
              id: 'form.formik.validation_schema.email',
            }),
          )
          .max(
            255,
            intl.formatMessage({
              id: 'form.formik.validation_schema.max.email',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'form.formik.validation_schema.required',
            }),
          ),
        phone_number: Yup.string()
          .matches(
            /^\d{7}$/,
            intl.formatMessage({
              id: 'form.formik.validation_schema.phone_number',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'form.formik.validation_schema.required',
            }),
          ),
        nif: Yup.string()
          .matches(
            /^\d{9}$/,
            intl.formatMessage({
              id: 'form.formik.validation_schema.nif',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'form.formik.validation_schema.required',
            }),
          ),
      })}
      onSubmit={async (values) => {
        const modifiedValues = { ...values };
        modifiedValues.phone_number = String(values.phone_number);
        modifiedValues.payment_service = 'SISP';
        modifiedValues.nif = String(values.nif);
        await onSubmit(modifiedValues);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', py: 1, gap: 1 }}>
            <Box>
              <TextInput
                label={intl.formatMessage({
                  id: 'form.formik.text_field_placeholder_email',
                })}
                name="email"
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <NumericTextField
                id="form_formik_phone_number"
                name="phone_number"
                label={intl.formatMessage({
                  id: 'form.formik.text_field_label_phone_number',
                })}
                value={values.phone_number}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
                handleBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <NumericTextField
                id="form_formik_nif"
                name="nif"
                label={intl.formatMessage({
                  id: 'form.formik.text_field_placeholder_nif',
                })}
                value={values.nif}
                error={Boolean(touched.nif && errors.nif)}
                helperText={touched.nif && errors.nif}
                handleBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'flex-end' }}
          >
            <Button color="primary" onClick={onClose} variant="text">
              {intl.formatMessage({
                id: 'form_formik.cancel_button_text',
              })}
            </Button>

            <Box>
              {isSubmitting ? (
                <LoadingButton
                  sx={{ width: '100%' }}
                  loading
                  variant="contained"
                >
                  {intl.formatMessage({
                    id: 'form_formik.submit_button_text',
                  })}
                </LoadingButton>
              ) : (
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {intl.formatMessage({
                    id: 'form_formik.submit_button_text',
                  })}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

SispForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default SispForm;
