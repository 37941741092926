import { MonetizationOnRounded } from '@mui/icons-material';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { Container, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import ContentBox from '../../components/ContentBox';
import CustomSnackBar from '../../components/CustomSnackBar';
import CustomText from '../../components/CustomText';
import Form from '../../components/Form';
import FormDialog from '../../components/FormDialog';
import IconLabelButton from '../../components/IconLabelButton';
import ItemStatus from '../../components/ItemStatus';
import PageHeaderTitle from '../../components/PageHeaderTitle';
import Wrapper from '../../components/Wrapper';
import axios from '../../lib/axios';
import theme from '../../theme';
import fetchSettings from '../../utils/fetchSettings';
import formatDate from '../../utils/formatDate';
import getLastLocation from '../../utils/getLastLocation';
import { getLanguageFromLocalStorage } from '../../utils/languagesFunctions';

const ItemDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const intl = useIntl();
  const language = getLanguageFromLocalStorage();
  const [isLoading, setIsLoading] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [clickedYes, setClickedYes] = useState(false);
  const [clickedNo, setClickedNo] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);

  const searchParams = new URLSearchParams(document.location.search);
  const itemId = searchParams.get('id');

  const fetchItem = async () => {
    try {
      const response = await axios.get(`/items/${itemId}`);
      if (response.data) {
        setData(response.data);
      } else {
        setIsLoading(false);
        navigate('/');
      }
    } catch (error) {
      // errors will be resolved in the future
      console.error('Ocorreu um erro ao carregar os dados da API:', error);
    }
  };

  const handleFetchs = async () => {
    setIsLoading(true);
    const [, settings] = await Promise.all([fetchItem(), fetchSettings()]);
    setIsPaymentEnabled(settings.allowOnlinePayment);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!data) {
      handleFetchs();
    }
  }, [data]);

  const checkForPhoneNumber = () => {
    return data.phone_number;
  };

  const navigateToSchedule = () => {
    navigate({
      pathname: '/item-details/schedule',
      search: `id=${itemId}`,
    });
  };

  const navigateToSearch = () => {
    navigate({
      pathname: '/',
    });
  };

  const handleCloseFormAndRedirect = () => {
    setOpenForm(false);

    if (clickedYes) {
      navigateToSchedule();
      setClickedYes(false);
    }

    if (clickedNo) {
      navigateToSearch();
      setClickedNo(false);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleOnSnackBarClosed = () => {
    setIsSnackOpen(false);
  };

  const handleYesButtonClick = () => {
    if (checkForPhoneNumber()) {
      navigateToSchedule();
    } else {
      setClickedYes(true);
      setOpenForm(true);
    }
  };

  const handleNoButtonClick = () => {
    if (checkForPhoneNumber()) {
      navigateToSearch();
    } else {
      setClickedNo(true);
      setOpenForm(true);
    }
  };

  const handlePayButtonClick = () => {
    navigate({
      pathname: '/item-details/payment-information',
      search: `id=${itemId}`,
    });
  };

  const handleSubmitPhoneNumber = async (values) => {
    const body = {
      phone_number: values.phone_number,
    };

    try {
      await axios.patch(`/items/${itemId}`, body);
      handleCloseFormAndRedirect();
    } catch (error) {
      setIsSnackOpen(true);
      handleCloseForm();
    }
  };

  const renderSchedulePickupButton = () => {
    if (
      getLastLocation(data).current_location &&
      !data.pickup_time &&
      data.route.available_pick_up_times &&
      data.payment &&
      data.payment.amount
    ) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <CustomText
            type="custom"
            color={theme.palette.primary.main}
            textAlign="center"
            fontSize={16}
            text={intl.formatMessage({
              id: 'item_details.schedule.confirmation',
            })}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              '@media (max-width: 743px)': {
                flexDirection: 'column',
                gap: 1,
              },
            }}
          >
            <IconLabelButton
              icon={<AccessTimeFilledOutlinedIcon />}
              onClick={handleYesButtonClick}
              variant="contained"
              title={intl.formatMessage({
                id: 'actions.yes',
              })}
            />
            <IconLabelButton
              onClick={handleNoButtonClick}
              variant="outlined"
              title={intl.formatMessage({
                id: 'actions.no',
              })}
            />
          </Box>
        </Box>
      );
    }

    return null;
  };

  const renderPayButton = () => {
    if (
      isPaymentEnabled &&
      data &&
      data.payment &&
      data.payment.status === false &&
      data.pickup_time
    ) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <CustomText
            type="custom"
            color={theme.palette.primary.main}
            textAlign="center"
            fontSize={16}
            text={intl.formatMessage({
              id: 'item_details.payment.confirmation',
            })}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              '@media (max-width: 743px)': {
                flexDirection: 'column',
                gap: 1,
              },
            }}
          >
            <IconLabelButton
              icon={<MonetizationOnRounded />}
              onClick={handlePayButtonClick}
              variant="contained"
              title={intl.formatMessage({
                id: 'actions.yes',
              })}
            />
            <IconLabelButton
              onClick={() =>
                navigate({
                  pathname: '/',
                })
              }
              variant="outlined"
              title={intl.formatMessage({
                id: 'actions.no',
              })}
            />
          </Box>
        </Box>
      );
    }
    return null;
  };

  const renderReceiptButton = () => {
    if (data && data.payment && data.payment.receipt_url) {
      return (
        <IconLabelButton
          icon={<ReceiptOutlinedIcon />}
          onClick={() =>
            navigate({
              pathname: '/item-details/payment-information',
              search: `id=${itemId}`,
            })
          }
          variant="contained"
          title={intl.formatMessage({
            id: 'actions.show.receipt',
          })}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'item_details.helmet.title',
          })}
        </title>
      </Helmet>
      <Wrapper>
        <Container maxWidth="md">
          <Box sx={{ my: 2 }}>
            <PageHeaderTitle title="item_details.title" goBackPath="/" />
          </Box>
          <FormDialog
            title={intl.formatMessage({
              id: 'item_details.contact_information',
            })}
            isOpen={openForm}
            handleClose={handleCloseForm}
          >
            <Form
              onSubmit={handleSubmitPhoneNumber}
              onClose={handleCloseForm}
            />
          </FormDialog>
          <CustomSnackBar
            severity="error"
            open={isSnackOpen}
            message={intl.formatMessage({ id: 'schedule.error' })}
            onClose={handleOnSnackBarClosed}
          />
          {!isLoading && isLoading !== null ? (
            <>
              <Box>
                <Box sx={{ my: 3 }}>{renderReceiptButton()}</Box>
                <ContentBox
                  language={language}
                  item={data}
                  isPaymentEnabled={isPaymentEnabled}
                  type="list"
                  buttonTitle={intl.formatMessage({
                    id: 'item_details.content_box.button_title',
                  })}
                  onClick={() =>
                    navigate({
                      pathname: '/item-details/payment-information',
                      search: `id=${itemId}`,
                    })
                  }
                />
              </Box>
              <ItemStatus
                item={data}
                formatDate={formatDate}
                language={language}
              />
              <Box sx={{ marginBottom: 3 }}>{renderSchedulePickupButton()}</Box>
              <Box sx={{ my: 3 }}>{renderPayButton()}</Box>
              <Box sx={{ mt: 2 }}>
                <ContentBox item={data} type="item-routes" />
              </Box>
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default ItemDetails;
