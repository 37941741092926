/**
 * Gets the current time in HH:mm format.
 *
 * @returns {string} The current time formatted as "HH:mm".
 */
const getTimeNow = () => {
  const originalTime = new Date();

  const hours = String(originalTime.getHours()).padStart(2, '0');
  const minutes = String(originalTime.getMinutes()).padStart(2, '0');

  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
};

module.exports = {
  getTimeNow,
};
