import { Box, Button, Container } from '@mui/material';
import PropTypes from 'prop-types';

import { SUPPORTED_LANGUAGES } from '../../utils/constants';
import CustomText from '../CustomText';
import Wrapper from '../Wrapper';

const ChooseLanguage = ({ changeLanguageOnLocale }) => {
  return (
    <Wrapper>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100vh',
            overflow: 'hidden',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Box>
              <img
                src="/logo.png"
                alt="atlantic logo"
                loading="lazy"
                width={120}
              />
            </Box>
            <CustomText
              type="custom"
              text="Atlantic Shipping Company"
              fontSize={36}
              textAlign="center"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {Object.values(SUPPORTED_LANGUAGES).map((language) => (
              <Button
                key={language.value}
                sx={{
                  width: '100%',
                }}
                variant="contained"
                onClick={() => changeLanguageOnLocale(language.value)}
              >
                {language.label}
              </Button>
            ))}
          </Box>
        </Box>
      </Container>
    </Wrapper>
  );
};

ChooseLanguage.propTypes = {
  changeLanguageOnLocale: PropTypes.func.isRequired,
};

export default ChooseLanguage;
