/**
 * Gets the current date in YYYY-MM-DD format.
 *
 * @returns {string} The current date formatted as "YYYY-MM-DD".
 */
const getCurrentDate = () => {
  return new Date().toISOString().split('T')[0];
};

module.exports = {
  getCurrentDate,
};
