import { Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const CustomSnackBar = ({ open, message, severity, onClose }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  const milliseconds = 6000;

  return (
    <Snackbar
      open={open}
      autoHideDuration={milliseconds}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        <Typography>{message}</Typography>
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomSnackBar;
