import { Email, Phone } from '@mui/icons-material';
import { Box, Container } from '@mui/material';

import CustomText from '../CustomText';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        backgroundColor: 'primary.main',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
        p: 3,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media (max-width: 450px)': {
            flexDirection: 'column',
            gap: 1.5,
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              '@media (max-width: 450px)': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5,
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <img
                src="/logo_white.png"
                alt="Atlantic Shipping Logo White"
                loading="lazy"
                width={22}
                height={22}
              />
              <CustomText
                text="Atlantic Shipping Company"
                type="custom"
                color="white"
                fontSize={18}
              />
            </Box>
            <Box
              sx={{
                '@media (max-width: 450px)': {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                },
              }}
            >
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <Email color="white" fontSize="small" />
                <CustomText
                  text="ship@atlanticshipping.com"
                  type="custom"
                  color="white"
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <Phone color="white" fontSize="small" />
                <CustomText
                  text="USA: 508-672-1870"
                  type="custom"
                  color="white"
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <Phone color="white" fontSize="small" />
                <CustomText
                  text="CVP: +238 9911010"
                  type="custom"
                  color="white"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <a href="https://chuva.io/" rel="noreferrer" target="_blank">
            <img
              src="/static/logo/powered_by.svg"
              alt="Chuva logo"
              loading="lazy"
              width={80}
              height="auto"
            />
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
