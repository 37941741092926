/**
 * Returns the name of the day of the week for the given date string.
 * @param {string} dateString - The date string in the format 'YYYY-MM-DD'.
 * @param {string} language - The language for the day of the week ('en' for English, 'pt' for Portuguese).
 * @returns {string} The name of the day of the week.
 */
const getDayOfWeek = (dateString, language) => {
  const date = new Date(dateString);

  const dayOfWeek = date.getDay();

  const daysOfWeekEN = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const daysOfWeekPT = [
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
    'Domingo',
  ];

  if (language === 'en') {
    return daysOfWeekEN[dayOfWeek];
  }

  return daysOfWeekPT[dayOfWeek];
};

module.exports = {
  getDayOfWeek,
};
