const formatDate = (locale, date, text, showTime = true) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  if (showTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = false;
  }

  const localDate = new Date(date);
  localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());

  const formattedDateWithTime = new Intl.DateTimeFormat(locale, options).format(
    localDate,
  );

  const formatDateWithoutTime = () => {
    const partsDate = date.split('-');
    const year = partsDate[0];
    const month = partsDate[1];
    const day = partsDate[2];

    if (locale === 'pt') {
      return `${day}/${month}/${year}`;
    }
    return `${month}/${day}/${year}`;
  };

  return showTime
    ? formattedDateWithTime.replace(/,/, ` ${text}`)
    : formatDateWithoutTime();
};
export default formatDate;
