import {
  DialogContentText,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

const FormDialog = ({
  title,
  description,
  descriptionComplement,
  isOpen,
  handleClose,
  children,
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText>
            <Typography component="span">{description}</Typography>
            <Typography component="span" fontWeight={500}>
              {descriptionComplement}
            </Typography>
          </DialogContentText>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};

FormDialog.defaultProps = {
  description: null,
  descriptionComplement: null,
};

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionComplement: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormDialog;
