import { Box } from '@mui/material';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';

import theme from '../../theme';
import CustomText from '../CustomText';

const ItemStatus = (props) => {
  const { item, formatDate, language } = props;

  const intl = useIntl();
  const currentLocation = item.route.locations.find(
    (location) => location.current_location,
  );

  const getScheduleInformation = () => {
    const {
      pickup_time: pickupTime,
      route: { estimated_arrival_date: estimatedArrivalDate },
    } = item;

    const isPickupTimeAvailable = Boolean(pickupTime);

    const dateKey = isPickupTimeAvailable
      ? 'content_box.details_container.pickup_date'
      : 'content_box.details_container.estimated_arrival_date';

    const dateLabel = `${intl.formatMessage({ id: dateKey })}:`;

    const dateValue = formatDate(
      language,
      isPickupTimeAvailable ? pickupTime : estimatedArrivalDate,
      intl.formatMessage({ id: 'format_date.text' }),
      isPickupTimeAvailable,
    );

    return `${dateLabel} ${dateValue}`;
  };

  const trackingDescription = item.route.locations.find(
    (location) => location.current_location,
  ).description;

  item.route.locations.sort((a, b) => a.order - b.order);
  const isItemArrived =
    item.route.locations[item.route.locations.length - 1].id ===
    currentLocation.id;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 2,
      }}
    >
      <Box
        sx={{
          p: 2,
          mt: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (max-width: 400px)': {
            gap: 2,
          },
        }}
      >
        <CustomText
          type="custom"
          color={theme.palette.primary.main}
          textAlign="center"
          fontSize={16}
          text={
            (trackingDescription && trackingDescription[language]) ||
            intl.formatMessage({
              id: isItemArrived
                ? 'item_status.box_info.is_ready_to_pickup'
                : 'item_status.box_info.en_route',
            })
          }
        />
        {((item.route.estimated_arrival_date &&
          !isItemArrived &&
          !trackingDescription) ||
          item.pickup_time) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 0.5,
              '@media (max-width: 400px)': {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <CustomText
              type="custom"
              color={theme.palette.primary.main}
              fontSize={16}
              fontWeight={600}
              text={getScheduleInformation()}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

ItemStatus.propTypes = {
  item: propTypes.shape({
    id: propTypes.string,
    name: propTypes.string,
  }).isRequired,
  formatDate: propTypes.func.isRequired,
  language: propTypes.string.isRequired,
};

export default ItemStatus;
