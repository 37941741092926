import ItemIdGuard from './components/ItemIdGuard';
import ItemDetails from './pages/ItemDetails';
import PaymentInformation from './pages/PaymentInformation';
import Schedule from './pages/Schedule';
import Search from './pages/Search';

const routes = [
  {
    path: '/',
    element: <Search />,
  },
  {
    path: '/item-details',
    element: (
      <ItemIdGuard>
        <ItemDetails />
      </ItemIdGuard>
    ),
  },
  {
    path: '/item-details/payment-information',
    element: (
      <ItemIdGuard>
        <PaymentInformation />
      </ItemIdGuard>
    ),
  },
  {
    path: '/item-details/schedule',
    element: (
      <ItemIdGuard>
        <Schedule />
      </ItemIdGuard>
    ),
  },
  {
    path: '*',
    element: (
      <ItemIdGuard>
        <Search />
      </ItemIdGuard>
    ),
  },
];

export default routes;
