import { ArrowBack } from '@mui/icons-material';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomText from '../CustomText';

const PageHeaderTitle = ({ title, goBackPath, itemId, hasBackButton }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
      }}
    >
      {hasBackButton && (
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            if (itemId) {
              navigate({
                pathname: goBackPath,
                search: `id=${itemId}`,
              });
            } else {
              navigate(goBackPath);
            }
          }}
        >
          <ArrowBack sx={{ cursor: 'pointer' }} />
        </Box>
      )}
      <Box>
        <CustomText
          text={intl.formatMessage({ id: `${title}` })}
          type="title"
        />
      </Box>
    </Box>
  );
};

PageHeaderTitle.defaultProps = {
  goBackPath: null,
  itemId: null,
  hasBackButton: true,
};

PageHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  goBackPath: PropTypes.string,
  itemId: PropTypes.string,
  hasBackButton: PropTypes.bool,
};

export default PageHeaderTitle;
